import axios from 'axios';

export default {
  getCardInformationWithPagination(pageNumber, pageSize, nameFilter, sortField, sortDesc) {
    return axios.get('/api/CardInformation', {
      params: {
        pageNumber,
        pageSize,
        nameFilter,
        sortField,
        sortDesc
      }
    });
  },
  addCardInformation: (inf) => axios.post('/api/CardInformation', inf),
  updateCardInformation: (inf) => axios.put(`/api/CardInformation/${inf.id}`, inf),
  deleteCardInformation: (id) => axios.delete(`/api/CardInformation/${id}`),
};
