/* eslint class-methods-use-this: ["error", { "exceptMethods": ["set", "get", "delete", "has"] }] */
import MyStorage from '@/store/modules/myStorage';

class UserSettingsService {
  set(key, value, userId) {
    MyStorage.set(key, value, userId);
  }

  get(key, userId) {
    return MyStorage.set(key, userId);
  }

  delete(key, userId) {
    MyStorage.delete(key, userId);
  }

  has(key, userId) {
    return MyStorage.has(key, userId);
  }
}

const userSettingsService = new UserSettingsService();
export default userSettingsService;
