<template>
  <el-container class="chat-input">
    <el-row style="margin-top: 0; width: 100%;">
    <el-col :span="24" v-if="modelChat" >
      <span  style="color: #888; font-size: 10px;" >Комментарий от 
      {{ moment(this.commentModel.updateDt)
          .add(this.userTimeZone, 'hours')
          .format('DD.MM.YYYY HH:mm') }}</span>
      </el-col>
    <el-col :span="22" >
      <el-input type="textarea" 
          maxlength="1000"
          show-word-limit
          v-model="message"
          v-on:keyup.enter.exact="submitHandler"
          class="message"
          solo
          no-resize
          :rows="modelChat ? 2 : 3"
          name="message"
          label="Type 'Enter' to send message"
        ></el-input>
    </el-col>
    <el-col :span="2">
      <el-button class="send-button" type="text" @click="submitHandler">
        <SendIcon />
      </el-button>
    </el-col>
  </el-row>
  </el-container>
</template>
<script>
import SendIcon from '@/assets/icons/send.svg';
import chatApi from '@/api/chat';
import { mapGetters } from 'vuex';

export default {
  name: 'ChatInput',
  components: { SendIcon },
  props: {
    value: { type: String, default: () => '' },
    idChat: { type: String, default: () => '' },
  },
  data() {
      return {
          message: '',
          commentModel: {}
      };
  },
  computed: {
    ...mapGetters('identity', ['userTimeZone']),
    idChatComp: {
        get() {
          return this.idChat;
        },
        set(val) {
          this.$emit('input', val);
        }
      },
    modelChat: {
        get() {
          return this.idChatComp ? this.getCommentsById(this.idChatComp) : '';
        },
        set(val) {
          this.$emit('input', val);
        }
      }
  },
  methods: {
    async submitHandler() {
      if (this.message.length > 0) {
        if (this.commentModel?.id) {
          const dataBody = {
            id: this.commentModel.id,
            commentId: this.commentModel.id,
            text: this.message
          };
          await chatApi.update(dataBody); 
        } else {
          const dataBody = {
            eventCorrelationId: this.value,
            isExternal: false,
            text: this.message
          };
          await chatApi.save(dataBody); 
        }
        await this.$emit('refresh');
        await this.$emit('updateMessage');
      }   
      this.resetMessage();        
    },
    async getCommentsById(id) {
        const resp = await chatApi.getCommentsById(id); 
        this.commentModel = resp.data;
        this.message = resp.data.text;
        return resp.data;
      },
    resetMessage() {
        this.message = '';
        this.commentModel = {};
    }
  }
};
</script>
<style>
  .chat-input{
    padding: 12px;
  }
  .send-button {
    margin: 0px 10px !important;
  }
</style>
