import axios from 'axios';

export default {
  getStaffCulpabilitiesWithPagination(pageNumber, pageSize, nameFilter, sortField, sortDesc) {
    return axios.get('/api/StaffCulpabilities', {
      params: {
        pageNumber, pageSize, nameFilter, sortField, sortDesc
      }
    });
  },
  getParentStaffCulpabilities() { return axios.get('/api/StaffCulpabilities/GetParentStaffCulpabilities'); },
  addStaffCulpability: (staffCulpability) => axios.post('/api/StaffCulpabilities', staffCulpability),
  updateStaffCulpability: (staffCulpability) => axios.put(`/api/StaffCulpabilities/${staffCulpability.id}`, staffCulpability),
  deleteStaffCulpability: (id) => axios.delete(`/api/StaffCulpabilities/${id}`), 
};
