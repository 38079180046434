<template>
  <div class="chat-list">    
    <div v-for="chat in chatItems" :key="chat.id" three-line  class="chat-container" >
      <Chat :chat="chat" />
      <el-button v-if="!chat.isRead && !chat.isNotEdit && chat.userName == user.userName" type="text" @click="editChat(chat)" icon="el-icon-edit">
      </el-button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Chat from './Chat';

export default {
  name: 'ChatList',
  props: {
    value: { type: String, default: () => '' },
    chatItems: { type: Array, default: () => [] },
  },
  components: {
      Chat
  },
  data() {
      return {
      };
  },
  computed: {
    ...mapGetters('identity', ['user']),
  },
  mounted() {
    const element = document.getElementById('chat-container');
    function resize() {
      const bodyheight = window.innerHeight - 64;
      
      if (typeof (element) !== 'undefined' && element != null) {
        element.style.height = `${bodyheight  }px`;
      }
    }
    window.addEventListener('resize', resize);
    resize();  
  },
  methods: {
    editChat(chat) {
      this.$emit('editChat', chat);
    }
  }
};
</script>
<style scoped>
.chat-container {
    width: 90%;
    margin: 10px auto;
    overflow: hidden;
    display: flex;  margin-right: 40px;
  }
.chat-list {
  height: inherit;
}
</style>
