class MyStorage {
  memoryStore = new Map();

  inMemory = false;

  constructor() {
    if (!window.localStorage) {
      this.inMemory = true;
      console.warn('window.localStorage не поддерживается! Данные будут утеряны после перегрузки');
    }
    try {
      window.localStorage.setItem('test', 'test');
      window.localStorage.removeItem('test');
    } catch (err) {
      console.warn('window.localStorage не поддерживается! Данные будут утеряны после перегрузки');
      this.inMemory = true;
    }
  }

  set(key, value, prefix = null) {
    if (prefix) {
      key = `${prefix}_${key}`;
    }

    if (this.inMemory) {
      this.memoryStore.set(key, value);
    } else {
      window.localStorage.setItem(key, value);
    }
  }

  get(key, prefix = null) {
    if (prefix) {
      key = `${prefix}_${key}`;
    }

    if (this.inMemory) {
      return this.memoryStore.get(key);
    } else {
      return window.localStorage.getItem(key);
    }
  }

  delete(key, prefix = null) {
    if (prefix) {
      key = `${prefix}_${key}`;
    }

    if (this.inMemory) {
      this.memoryStore.delete(key);
    } else {
      window.localStorage.removeItem(key);
    }
  }

  has(key, prefix = null) {
    if (prefix) {
      key = `${prefix}_${key}`;
    }

    if (this.inMemory) {
      return !!this.memoryStore.has(key);
    } else {
      for (let i = 0; i < window.localStorage.length; i++) {
        const keyStore = window.localStorage.key(i);
        if (keyStore === key) {
          return true;
        }
      }
      return false;
    }
  }
}

const myStorage = new MyStorage();
export default myStorage;
