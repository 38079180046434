// eslint-disable-next-line max-classes-per-file
import { reactive } from 'vue';

class EventMappingStore {
  state = reactive({
    filterModel: this.getEmpty(),
  });

  // eslint-disable-next-line class-methods-use-this
  getEmpty() {
    return  {
      startEventTimeFilter: '',
      endEventTimeFilter: '',
      eventDateRange: [],
      primaryEquipmentFilter: '',
      secondEquipmentPanelFilter: '',
      baseVoltageFilter: [],
      energyObjectFilter: [],
      eoRegionFilter: [],
      showDeleted: false,
      dcOrganizationFilter: [],

      psrTypeFilter: [],
      estimationFilter: [],
      shortCircuitFormFilter: [],
      shortCircuitPlaceFilter: [],
      showNeedCorrect: null,
      numberFilter: '',
      staffCategoryFilter: [],
      accidentEquipmentFilter: [],
      staffCulpabilitiesFilter: [],
      technicalReasonsFilter: [],
      secondEquipmentTypesFilter: [],
      misoperationReasonsFilter: [],      
      eventSourcesFilter: '',
      eventSourcesFileNameFilter: [],
      ownerOrganizationsFilter: [],
      producerOrganizationsFilter: [],
      cardInformationFilter: [],
      executionsFilter: [],
      productAssetModelsFilter: [],
      registrationGroupFilter: [],
      faultedPhasesFilter: [],
      bmmnCheckTypesFilter: [],
      correlationStatuses: [],
    };
  }
}

export default EventMappingStore;



