<template>
  <div class="my-flex-container-column">
    <div>
      <el-button type="text" @click="clearSelectedSecondEquipments" icon="el-icon-circle-close">Очистить все</el-button>
      <div class="selected-tags">
        <el-tag
          v-for="tag in selectedSecondEquipments"
          :key="tag.id"
          :closable="!tag.disabled"
          size="small"
          @close="handleSecondEquipmentDelete(tag)"
          class="mr-1 mb-1">
          {{tag.name}}
        </el-tag>
      </div>
    </div>
    <div style="text-align: center">
      <pagination
        style="text-align: center"
        :total-count="totalCount"
        :page-size.sync="pageSize"
        :current-page.sync="pageNumber"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
        <div>      
          <el-button @click="toggleAllNodes" type="text" class="ml-2">Свернуть/развернуть всё</el-button>
          <span class="total_items">Всего: {{this.seCount}}</span>
        </div>
    <div v-loading="loading" class="my-flex-data-content">
      <infinite-tree
        ref="tree"
        :data="secondEquipments"
        :style="{ height: '100%'}"
        @on-check-click="handleCheck"
        @on-open-node="applyChecking"
      >
        <div class="infinite-tree-node" slot-scope="{ node }">{{ node.color }}</div>
      </infinite-tree>
    </div>
  </div>
</template>



<script>
import secondEquipmentsApi from '@/api/nsi/secondEquipments';
import pagination from '@/components/Ui/Pagination';
import dateHelpers from '@/mixins/dateHelpers';
import infiniteTree from '@/components/Ui/InfiniteTree/InfiniteTree';

export default {
  name: 'SecondEquipmentsSelectTable',
  props: {
    filterModel: { type: Object, default: () => {} },
    filterActive: { type: Boolean, default: () => true },
    data: { type: Array, default: () => [] },
    fromEventCard: { type: Boolean, default: () => false }
  },
  mixins: [dateHelpers],
  components: {
    infiniteTree,
    pagination,
  },
  data() {
    return {
      // tableSizeConstant: 130,
      loading: false,
      secondEquipments: [],
      pageNumber: 1,
      pageSize: 10,
      totalCount: 0,
      sortField: 'name',
      sortDesc: false,
      radioGroupModel: {},
      selectedSecondEquipments: [],
      modalClass: 'types__second-equipments-modal',
      tags: [],
      seCount: 0
    };
  },
  watch: {
    filterModel: {
      handler() {
        this.getSecondEquipments();
      },
      deep: true
    },
    selectedSecondEquipment: {
      handler() {
        this.tags = this.selectedSecondEquipments;
      }
    }
  },
  computed: {
    tableSizeConstant() {
      if (!this.filterActive) {
        return 10;
      }

      return 15;
    },
    treeContainer() {
      return this.$refs.tree;
    }
  },
  created() {
    if (this.data && this.data.length > 0) this.selectedSecondEquipments = this.data.map((t) => ({ name: t.value, ...t }));
  },
  async mounted() {
    await this.getSecondEquipments();
  },
  methods: {
    async getSecondEquipments() {
      this.loading = true;
      const params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        primaryEquipmentIdFilter: this.filterModel.showAll ? null : this.filterModel.primaryEquipmentIdFilter,
        secondEquipmentPanelIdFilter: this.filterModel.showAll ? null : this.filterModel.secondEquipmentPanelIdFilter,
        nameFilter: this.filterModel.nameFilter,
        psrTypeIdsFilter: this.filterModel.psrTypeIdsFilter,
        energyObjectNameFilter: this.filterModel.energyObjectNameFilter,
        primaryEquipmentNameFilter: this.filterModel.primaryEquipmentNameFilter,
        secondEquipmentPanelNameFilter: this.filterModel.secondEquipmentPanelNameFilter,
        skipEoObject: this.filterModel.skipEoObject,
        isLocalNsiFilter: this.filterModel.isLocalNsiFilter,
        sortField: this.sortField,
        sortDesc: this.sortDesc,
        fromEventCard: this.fromEventCard,
        dispatchAdministrationOrganizationsFilter: this.filterModel.dispatchAdministrationOrganizationsFilter,
        dispatchManagementOrganizationsFilter: this.filterModel.dispatchManagementOrganizationsFilter,
        eoRegionFilter: this.filterModel.eoRegionFilter,
        ownerOrganizationsFilter: this.filterModel.ownerOrganizationsFilter,
        registrationGroupFilter: this.filterModel.registrationGroupFilter,
        executionsFilter: this.filterModel.executionsFilter,  
        techAdministrationOrganizationsFilter: this.filterModel.techAdministrationOrganizationsFilter,
        techManagementOrganizationsFilter: this.filterModel.techManagementOrganizationsFilter,
        technicalOrganizationsFilter: this.filterModel.technicalOrganizationsFilter,
        startCommissioningTimeRange: this.filterModel.periodCommissioningTimeRange?.length > 0 ? this.toUserDate(this.filterModel.periodCommissioningTimeRange[0], 'YYYY-MM-DD') : null,
        endCommissioningTimeRange: this.filterModel.periodCommissioningTimeRange?.length > 0 ? this.toUserDate(this.filterModel.periodCommissioningTimeRange[1], 'YYYY-MM-DD') : null,
        startDecommissioningTimeRange: this.filterModel.periodDecommissioningTimeRange?.length > 0 ? this.toUserDate(this.filterModel.periodDecommissioningTimeRange[0], 'YYYY-MM-DD') : null,
        endDecommissioningTimeRange: this.filterModel.periodDecommissioningTimeRange?.length > 0 ? this.toUserDate(this.filterModel.periodDecommissioningTimeRange[1], 'YYYY-MM-DD') : null,
        showDecommissionedSE: this.filterModel.showDecommissionedSE
      };
      const res = await secondEquipmentsApi.getSecondEquipmentsWithPagination(params);
      if (res.data) {
        this.secondEquipments = res.data.items;
        this.totalCount = res.data.totalCount;
        this.seCount = res.data.treeItemsCount;
        this.treeContainer.tree.loadData(this.secondEquipments);
        this.applyChecking();
      }
      this.loading = false;
    },
    async handleSizeChange() {
      this.pageNumber = 1;
      await this.getSecondEquipments();
    },
    async handleCurrentChange() {
      await this.getSecondEquipments();
    },
    async handleSortChange(event) {
      if (event.order === null) {
        this.sortDesc = null;
        this.sortField = null;
      } else {
        this.sortDesc = event.order === 'descending';
        this.sortField = event.prop;
      }
      await this.getSecondEquipments();
    },
    handleCheck(node) {
      if (node.state.checked) {
        this.selectedSecondEquipments.push(node);

        const nodes = this.treeContainer.tree.nodes.filter((t) => t.nodeType === 'secondEquipment' && t.baseId === node.baseId);
        if (nodes && nodes.length > 0) {
          nodes.forEach((n) => {
            n.state.checked = true;
          });

          this.treeContainer.rerender();
        }
      } else {
        const index = this.selectedSecondEquipments.findIndex((x) => x.baseId === node.baseId);
        if (index > -1) {
          this.selectedSecondEquipments.splice(index, 1);

          const nodes = this.treeContainer.tree.nodes.filter((t) => t.nodeType === 'secondEquipment' && t.baseId === node.baseId);
          if (nodes && nodes.length > 0) {
            nodes.forEach((n) => {
              n.state.checked = false;
            });

            this.treeContainer.rerender();
          }
        }
      }

      this.$emit('on-update', this.selectedSecondEquipments);
    },
    applyChecking() {
      const { nodes } = this.treeContainer.tree;

      nodes.forEach((node) => {
        if (node.nodeType === 'secondEquipment') {
          if (this.selectedSecondEquipments.some((t) => t.baseId === node.baseId)) {
            node.state.checked = true;
          } else {
            node.state.checked = false;
          }

          node.сheckAllowed = true;
          node.viewAllowed = false;
        }
      });

      this.treeContainer.rerender();
    },
    handleSecondEquipmentDelete(secondEquipment) {
      this.selectedSecondEquipments = this.selectedSecondEquipments.filter((s) => s.baseId !== secondEquipment.baseId);
      this.applyChecking();

      this.$emit('on-update', this.selectedSecondEquipments);
    },
    clearSelectedSecondEquipments() {
      this.selectedSecondEquipments = [];
      this.applyChecking();

      this.$emit('on-update', this.selectedSecondEquipments);
    },
    toggleAllNodes() {
      this.$refs.tree.toggleNodes();
      this.applyChecking();
    }
  }
};
</script>

<style scoped lang="scss">
.total_items {
   float: right; 
   color: #909399;
   font-family: Montserrat-Regular;
   font-size: 14px;
   margin-top: 12px;
   margin-right: 8px;
}
.selected-tags {
  max-height: 100px;
  overflow-x: auto;
}
</style>
