<script>


import staffCulpabilities from '@/api/nsi/staffCulpabilities';
import filterHelper from '@/mixins/filterHelper';

export default {
  name: 'StaffCulpabilitySelect',
  mixins: [filterHelper],
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    filterable: {
      type: Boolean,
      default: true
    },
    clearable: {
      type: Boolean,
      default: true
    },
    popperClass: String,
    value: { type: [Array, Object], default: () => '' },
    collapseTags: Boolean,
  },
  data() {
    return {
      items: [],
      //  value: null,
    };
  },
  async created() {
    const staffCulp = await staffCulpabilities.getStaffCulpabilitiesWithPagination(1, 1000000, null, 'name', false);
    if (staffCulp.data) {
      this.items =  this.prepare(staffCulp.data.items);
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  methods: {
    prepare(data = []) {
      const items = [];
      data.forEach((el) => {
        items.push(el);
        el.children.forEach((el2) => {
          items.push(el2);
        });
      });

      return items.filter((el) => el.actual && el.code).sort((a, b) => (a.code > b.code ? 1 : -1));
    },
    getList(tree, items) {
      if (!tree) {
        return;
      }
      tree.forEach((el) => {
        items.push(el);
        if (el.children && el.children.length) {
          this.getList(el.children, items);
        }
      });
    },
    handleChange(val) {
      this.$emit('change', val);
    },
    selectAllValues() {
      this.handleChange(this.getSelectedValues(this.items, this.$refs.staffCulpabilitiesSelector.query, (el) => (el.code + el.name), (el) => (el.name)));
      // this.handleChange(this.items.map((el) => el.name));
    },
  }
};

</script>

<template>
  <el-select
    size="small"
    ref="staffCulpabilitiesSelector"
    :value="value"
    :filterable="filterable"
    :clearable="clearable"
    :multiple="multiple"
    @change="handleChange"
    :collapse-tags="collapseTags"
    :disabled="disabled"
    :popper-class="popperClass"
    placeholder="Выбрать">
    <div class="pl-3">
      <el-button type="text" size="small" @click="selectAllValues($event)">Выбрать всё</el-button>
    </div>
    <el-option
      v-for="item in items"
      :key="item.id"
      :label="'('+item.code+') '+item.name"
      :value="item.name"/>
  </el-select>
</template>

<style scoped>

</style>
