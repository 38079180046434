const filterVersion = 3;

export default {
  saveFilter(filterName, filterState) {
    filterState.version = filterVersion;
    localStorage.setItem(filterName, JSON.stringify(filterState));
  },

  getFilter(filterName) {
    const filterState = JSON.parse(localStorage.getItem(filterName));
    if (!filterState || this.isEmpty(filterState)) {
      return null;
    }
    if (filterState.version !== filterVersion) {
      return null;
    }
    return filterState;
  },
  getFilterOrDefault(filterName, defaultValue = null) {
    const filterState = JSON.parse(localStorage.getItem(filterName));
    if (!filterState || this.isEmpty(filterState)) {
      return defaultValue;
    }
    if (filterState.version !== filterVersion) {
      return defaultValue;
    }
    return filterState;
  },

  isEmpty(obj) {
    // eslint-disable-next-line no-restricted-syntax
    for (const prop in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }

    return JSON.stringify(obj) === JSON.stringify({});
  },
  
  isGuid(value) {
    // Регулярное выражение для проверки формата GUID
    const guidPattern = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    return guidPattern.test(value);
  },
};
